/* eslint-disable react/jsx-props-no-spreading */

import {
  Tabs,
  Typography,
  TextInput,
  Button,
  Toast,
  Pill,
} from '@team-seenit/atoms';
import React, { useEffect, useRef, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { createPortal } from 'react-dom';
import { useLazyQuery, useMutation } from '@apollo/client';
import { FormError } from '../../FormError';
import { LowerThirdsFormSection } from './LowerThirdFormSection';
import { mutations, queries } from '../LowerThirds.gql';
import { LowerThirdFormProps } from './LowerThirdsForm.types';
import * as Styled from '../LowerThirds.styled';
import { Error } from '../../../../molecules';
import { defaultFormProps } from '../helpers';
import { FormErrors } from '../../FormErrors';

export const LowerThirdsForm = ({
  orgId,
  dataEl,
  selectedLowerThird,
  setSelectedLowerThird,
  refetchLowerThirds,
}: LowerThirdFormProps) => {
  const [briefCurrentTab, setBriefCurrentTab] = useState('background');
  const toastRef = useRef();

  const rootElement = document.getElementById('root');

  const showToast = ((toastRef.current || {}) as {
    showToast: (message: string) => void;
  })?.showToast;

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
    getValues,
    trigger,
  } = useFormContext();

  const [
    createLowerThird,
    { error: createLowerThirdError, reset: resetCreateLowerThird },
  ] = useMutation(mutations.createLowerThird, {
    onCompleted: () => {
      refetchLowerThirds();
      showToast('Lower third created successfully');
      setSelectedLowerThird(null);
      reset(defaultFormProps);
    },
  });

  const [
    updateLowerThird,
    { error: updateLowerThirdError, reset: resetUpdateLowerThird },
  ] = useMutation(mutations.updateLowerThird, {
    onCompleted: () => {
      showToast('Lower third updated successfully');
      setSelectedLowerThird(null);
      reset(defaultFormProps);
    },
  });

  const lowerThirdsTabs = [
    { dataEl: 'Background', id: 'background', value: 'background' },
    { dataEl: 'PrimaryText', id: 'primaryText', value: 'primaryText' },
    { dataEl: 'SecondaryText', id: 'secondaryText', value: 'secondaryText' },
  ];

  const [getLowerThird] = useLazyQuery(queries.getLowerThird, {
    onCompleted: data => {
      setValue('name', data?.seenit?.lowerThird?.name);

      Object.values(lowerThirdsTabs).forEach(({ value }) => {
        Object.keys(data?.seenit?.lowerThird?.[value]).forEach(item => {
          if (item !== '__typename' && item !== 'editorMedia')
            setValue(
              `${value}.${item}`,
              data?.seenit?.lowerThird?.[value][item]
            );
          if (item === 'editorMedia' || item === 'font') {
            setValue(
              `${value}.${item}`,
              data?.seenit?.lowerThird?.[value]?.[item]?.editorMediaId
            );
          }
          if (item === 'colorByReference') {
            setValue(
              `${value}.${item}`,
              data?.seenit?.lowerThird?.[value]?.[item]?.editorOrgAssetId
            );
          }
        });
      });
      trigger();
    },

    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    if (selectedLowerThird)
      getLowerThird({ variables: { lowerThirdId: selectedLowerThird } });
    resetCreateLowerThird();
    resetUpdateLowerThird();
  }, [selectedLowerThird]);

  useEffect(() => {
    if (orgId) {
      resetCreateLowerThird();
      resetUpdateLowerThird();
      reset(defaultFormProps);
    }
  }, [orgId]);

  const buttonTitle = () => {
    return selectedLowerThird ? 'Update lower third' : 'Create lower third';
  };

  const formFilterObject = formObject => {
    const obj = Object.fromEntries(
      Object.entries(formObject).filter(
        ([, value]) => Boolean(value) && value !== '-1'
      )
    );

    return obj;
  };

  const mutationData = data => ({
    background: formFilterObject(data.background),
    name: data.name,
    primaryText: formFilterObject(data.primaryText),
    secondaryText: formFilterObject(data.secondaryText),
  });

  const buttonClicked = data => {
    if (selectedLowerThird) {
      updateLowerThird({
        variables: {
          data: { ...mutationData(data) },
          lowerThirdId: selectedLowerThird,
        },
      });
    } else {
      createLowerThird({
        variables: { data: { ...mutationData(data), orgId } },
      });
    }
  };

  return (
    <Styled.Wrapper>
      <Pill
        color="red"
        readOnly
        text={
          selectedLowerThird ? `Updating lower third` : `Creating lower third`
        }
      />
      <Tabs
        onChange={setBriefCurrentTab}
        selectedTab={briefCurrentTab}
        tabs={lowerThirdsTabs}
        tabStyle="switch"
      />
      <form onSubmit={handleSubmit(buttonClicked)}>
        <Controller
          name="name"
          control={control}
          render={({ field }) => (
            <Styled.InputWrapper>
              <Typography
                lines={0}
                variant="label"
                css={{ marginBottom: '4px' }}
              >
                Lower third name
              </Typography>
              <TextInput
                {...field}
                value={getValues('name') ?? ''}
                placeholder="Lower third name goes here..."
              />
              <FormError errors={errors} field="name" />
            </Styled.InputWrapper>
          )}
        />

        {briefCurrentTab && (
          <LowerThirdsFormSection
            dataEl={dataEl}
            section={briefCurrentTab}
            orgId={orgId}
          />
        )}

        <Button
          animation="pulse"
          dataEl="OpeningMediaSubmitButton"
          id="openingMediaSubmit"
          large
          type="submit"
          width="100%"
        >
          {buttonTitle()}
        </Button>
        <FormErrors errors={errors} />
        <Error rawErrors={createLowerThirdError || updateLowerThirdError} />
        {!!rootElement &&
          createPortal(
            <Toast
              dataEl={`${dataEl}Toast`}
              autoClose="3000"
              containerId={`${dataEl}ToastContainer`}
              position="top-center"
              ref={toastRef}
              toastId={`${dataEl}Toast`}
            />,
            rootElement
          )}
      </form>
    </Styled.Wrapper>
  );
};
