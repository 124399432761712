import React, { useEffect, useState } from 'react';
import { useReactiveVar, useLazyQuery, useMutation } from '@apollo/client';
import { Button, Typography, Select } from '@team-seenit/atoms';
import { StatsCard } from '@team-seenit/molecules';
import { editorBrandsDefaultFormPropsVar } from '../variables';
import * as Styled from '../Brands.styled';
import { destructureDataForOrgBrands, queries, mutations } from '../Brands.gql';
import { CurrentStateActionCard } from '../../CurrentStateActionCard';
import { prepareShareableBrandsSelect } from '../helpers';
import { BrandsCurrentStateProps } from './BrandsCurrentState.types';
import { OrgTypeEnum } from '../../EditorConfigEnums';

export const BrandsCurrentState = ({
  dataEl,
  orgType,
  selectedBrand,
  setSelectedBrand,
  resetForm,
  brands,
  sharedBrands,
  refetchBrands,
  refetchSharedBrands,
  error,
  orgId,
}: BrandsCurrentStateProps) => {
  const [brandToAdd, setBrandToAdd] = useState(null);

  const [
    getAdminShareableBrands,
    { data: getAdminShareableBrandsData, refetch: refetchAdminShareableBrands },
  ] = useLazyQuery(queries.getAdminShareableBrands, {
    fetchPolicy: 'cache-first',
  });

  const [deleteBrand] = useMutation(mutations.deleteBrand, {
    onCompleted: () => {
      resetForm();
      refetchBrands();
    },
  });

  const [updateBrand] = useMutation(mutations.updateBrand, {
    onCompleted: () => {
      refetchSharedBrands();
      refetchAdminShareableBrands();
      setBrandToAdd(null);
    },
  });

  useEffect(() => {
    if (orgId && orgType === OrgTypeEnum.AllOrganisations)
      getAdminShareableBrands();
  }, [orgId]);

  const shareableBrandError = () => {
    const brand = getAdminShareableBrandsData?.seenit?.adminShareableBrands?.find(
      shareableBrand => shareableBrand.brandId === brandToAdd
    );

    if (brand?.orgIds?.includes(orgId)) {
      return true;
    }
  };

  const editorBrandsDefaultFormProps = useReactiveVar(
    editorBrandsDefaultFormPropsVar
  );

  const { icon, title, stats, color, items } = destructureDataForOrgBrands(
    brands
  );

  const { items: sharedItems } = destructureDataForOrgBrands(sharedBrands);

  if (error.length) return <h1>GQL Error</h1>;

  return (
    <Styled.Wrapper dataEl={dataEl}>
      <StatsCard color={color} icon={icon} stats={stats} title={title} />

      <Button
        onClick={() => {
          resetForm(editorBrandsDefaultFormProps);
          setSelectedBrand(null);
        }}
      >
        Reset form / Create brand
      </Button>

      {items.map(item => (
        <CurrentStateActionCard
          key={item.brandId}
          id={item.brandId}
          onDeleteClick={() =>
            deleteBrand({ variables: { brandId: item.brandId } })
          }
          disabled={selectedBrand === item.brandId}
          name={item.name}
          onCardClick={() => setSelectedBrand(item.brandId)}
        />
      ))}
      {orgType === OrgTypeEnum.AllOrganisations && (
        <>
          <Typography variant="header">Shared brands</Typography>

          {sharedItems.map(adminBrand => (
            <CurrentStateActionCard
              id={adminBrand.brandId}
              key={adminBrand.brandId}
              onDeleteClick={() => {
                updateBrand({
                  variables: {
                    brandId: adminBrand.brandId,
                    data: {
                      orgIds: adminBrand.orgIds.filter(id => id !== orgId),
                    },
                  },
                });
              }}
              disabled
              name={adminBrand.name}
            />
          ))}
          <>
            <Styled.InputField>
              <Typography lines={0} variant="label">
                Shared brands
              </Typography>
              <Select
                dataEl={dataEl}
                options={prepareShareableBrandsSelect(
                  getAdminShareableBrandsData
                )}
                id="editorMedia"
                value={brandToAdd ?? -1}
                onChange={id => {
                  if (id === '-1') setBrandToAdd(null);
                  else setBrandToAdd(id);
                }}
              />
              {shareableBrandError() && (
                <Typography color="red">
                  This brand is already shared with this organisation
                </Typography>
              )}
            </Styled.InputField>
            <Button
              disabled={shareableBrandError() || brandToAdd === null}
              onClick={() => {
                updateBrand({
                  variables: {
                    brandId: brandToAdd,
                    data: {
                      orgIds: [
                        ...(getAdminShareableBrandsData?.seenit?.adminShareableBrands?.find(
                          brand => brand.brandId === brandToAdd
                        )?.orgIds ?? []),
                        orgId,
                      ],
                    },
                  },
                });
              }}
            >
              Add shared brand to org
            </Button>
          </>
        </>
      )}
    </Styled.Wrapper>
  );
};
