import * as yup from 'yup';

export const validationSchema = yup.object({
  name: yup.string().required('Name is required'),
  background: yup.object().shape(
    {
      editorMedia: yup
        .string()
        .when('colorByReference', ([colourByReference], schema) => {
          return colourByReference
            ? schema
                .nullable()
                .oneOf([null], 'Choose either editorMedia or colorByReference')
            : schema.required(
                'Either editorMedia or colorByReference is required'
              );
        }),
      colorByReference: yup
        .string()
        .when('editorMedia', ([editorMedia], schema) => {
          return editorMedia
            ? schema
                .nullable()
                .oneOf([null], 'Choose either editorMedia or colorByReference')
            : schema.required(
                'Either editorMedia or colorByReference is required'
              );
        }),
      duration: yup
        .number()
        .required('Duration is required')
        .transform((_, val) => (val === '' ? null : val))
        .typeError('Only numbers are allowed'),
      fadeIn: yup
        .number()
        .nullable()
        .transform((_, val) => (val === '' ? null : val))
        .typeError('Only numbers are allowed'),
      fadeInRequired: yup.boolean().nullable(),
      fadeOut: yup
        .number()
        .nullable()
        .transform((_, val) => (val === '' ? null : val))
        .typeError('Only numbers are allowed'),
      fadeOutRequired: yup.boolean().nullable(),
      height: yup
        .number()
        .required('Height is required')
        .transform((_, val) => (val === '' ? null : val))
        .typeError('Only numbers are allowed'),
      positionX: yup
        .number()
        .required('positionX is required')
        .transform((_, val) => (val === '' ? null : val))
        .typeError('Only numbers are allowed'),
      positionY: yup
        .number()
        .required('positionY is required')
        .transform((_, val) => (val === '' ? null : val))
        .typeError('Only numbers are allowed'),
      minWidth: yup
        .number()
        .required('minWidth is required')
        .transform((_, val) => (val === '' ? null : val))
        .typeError('Only numbers are allowed'),
      widthCanGrow: yup.boolean(),
    },
    ['editorMedia', 'colorByReference']
  ),
  primaryText: yup.object({
    fontSize: yup
      .number()
      .required('fontSize is required')
      .transform((_, val) => (val === '' ? null : val))
      .typeError('Only numbers are allowed'),
    colorByReference: yup.string().required('colorByReference is required'),
    font: yup.string().required('font is required'),
    positionX: yup
      .number()
      .required('positionX is required')
      .transform((_, val) => (val === '' ? null : val))
      .typeError('Only numbers are allowed'),
    positionY: yup
      .number()
      .required('positionY is required')
      .transform((_, val) => (val === '' ? null : val))
      .typeError('Only numbers are allowed'),
    positonYWhenNoSecondaryText: yup
      .number()
      .required('positonYWhenNoSecondaryText is required')
      .transform((_, val) => (val === '' ? null : val))
      .typeError('Only numbers are allowed'),
  }),
  secondaryText: yup.object({
    fontSize: yup
      .number()
      .required('fontSize is required')
      .transform((_, val) => (val === '' ? null : val))
      .typeError('Only numbers are allowed'),
    colorByReference: yup.string().required('colorByReference is required'),
    font: yup.string().required('font is required'),
    positionX: yup
      .number()
      .required('positionX is required')
      .transform((_, val) => (val === '' ? null : val))
      .typeError('Only numbers are allowed'),
    positionY: yup
      .number()
      .required('positionY is required')
      .transform((_, val) => (val === '' ? null : val))
      .typeError('Only numbers are allowed'),
    positonYWhenNoSecondaryText: yup
      .number()
      .required('positonYWhenNoSecondaryText is required')
      .transform((_, val) => (val === '' ? null : val))
      .typeError('Only numbers are allowed'),
  }),
});
