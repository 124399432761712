/* eslint-disable react/jsx-props-no-spreading */

import React, { useState, useEffect, useRef } from 'react';
import { useMutation, useLazyQuery, useReactiveVar } from '@apollo/client';
import {
  Button,
  Tabs,
  Pill,
  TextInput,
  Typography,
  Select,
  Toast,
} from '@team-seenit/atoms';
import { Controller, useFormContext } from 'react-hook-form';
import { createPortal } from 'react-dom';
import { queries, mutations } from '../Brands.gql';
import {
  editorBrandsOrgDataVar,
  editorBrandsDefaultFormPropsVar,
} from '../variables';
import { BrandsFormSection } from './BrandsFormSection';
import * as Styled from '../Brands.styled';
import { FormError } from '../../FormError';
import { BrandsBriefProps } from './BrandsForm.types';
import { Error } from '../../../../molecules';
import { FormErrors } from '../../FormErrors';

const tabs = [
  { dataEl: 'Logo', id: 'logo', value: 'logo' },
  { dataEl: 'Selections', id: 'selections', value: 'selections' },
];

export const BrandsForm = ({
  dataEl,
  orgId,
  selectedBrand,
  setSelectedBrand,
  refetchBrands,
}: BrandsBriefProps) => {
  const [briefCurrentTab, setBriefCurrentTab] = useState('logo');
  const editorBrandsOrgData = useReactiveVar(editorBrandsOrgDataVar);
  const editorBrandsDefaultFormProps = useReactiveVar(
    editorBrandsDefaultFormPropsVar
  );
  const toastRef = useRef();

  const rootElement = document.getElementById('root');

  const showToast = ((toastRef.current || {}) as {
    showToast: (message: string) => void;
  })?.showToast;

  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    reset,
    trigger,
    formState: { errors },
  } = useFormContext();

  const [getBrand] = useLazyQuery(queries.getBrand, {
    fetchPolicy: 'no-cache',
    onCompleted: data => {
      const adminBrand = data?.seenit?.adminBrand;

      setValue('name', adminBrand?.name);
      setValue('orientation', adminBrand?.orientation);
      setValue(
        'logo.editorMediaId',
        adminBrand?.logo?.editorMedia?.editorMediaId
      );
      setValue('logo.height', adminBrand?.logo?.height);
      setValue('logo.positionX', adminBrand?.logo?.positionX);
      setValue('logo.positionY', adminBrand?.logo?.positionY);
      setValue('logo.width', adminBrand?.logo?.width);

      const iterableFormSections = [
        'openingVideos',
        'closingVideos',
        'musicTracks',
      ];

      iterableFormSections.forEach(section => {
        const sectionValue = getValues(section);

        Object.keys(sectionValue).forEach(item => {
          if (adminBrand[section] === null)
            setValue(`${section}.${item}`, false);
          else if (
            adminBrand[section].some(media => media.editorMediaId === item)
          ) {
            setValue(`${section}.${item}`, true);
          } else {
            setValue(`${section}.${item}`, false);
          }
        });
      });

      Object.keys(getValues('lowerThirds')).forEach(item => {
        if (adminBrand.lowerThirds === null)
          setValue(`lowerThirds.${item}`, false);
        else if (
          adminBrand.lowerThirds.some(
            lowerThird => lowerThird.lowerThirdId === item
          )
        ) {
          setValue(`lowerThirds.${item}`, true);
        } else {
          setValue(`lowerThirds.${item}`, false);
        }
      });
    },
  });

  const [
    createBrand,
    { error: createBrandError, reset: resetCreateBrand },
  ] = useMutation(mutations.createBrand, {
    onCompleted: () => {
      showToast('Brand created successfully');
      setSelectedBrand(null);
      reset(editorBrandsDefaultFormProps);
      refetchBrands();
    },
  });

  const [
    updateBrand,
    { error: updateBrandError, reset: resetUpdateBrand },
  ] = useMutation(mutations.updateBrand, {
    onCompleted: () => {
      showToast('Brand updated successfully');
      setSelectedBrand(null);
      reset(editorBrandsDefaultFormProps);
      refetchBrands();
    },
  });

  useEffect(() => {
    if (selectedBrand) {
      getBrand({ variables: { brandId: selectedBrand } });
    }
    reset(editorBrandsDefaultFormProps);
    resetCreateBrand();
    resetUpdateBrand();
  }, [selectedBrand]);

  const editorMediaArrayToObject = array => {
    const obj = array.reduce((acc, item) => {
      acc[item.editorMediaId] = false;
      return acc;
    }, {});

    return obj;
  };

  const lowerThirdsArrayToObject = array => {
    const obj = array.reduce((acc, item) => {
      acc[item.lowerThirdId] = false;
      return acc;
    }, {});

    return obj;
  };

  const [getopeningMediaMedia, { data: openingMediaMediaData }] = useLazyQuery(
    queries.getMediaByType,
    {
      onCompleted: data => {
        setValue(
          'openingVideos',
          editorMediaArrayToObject(data.seenit.editorMedia)
        );
      },
    }
  );

  const [getclosingMediaMedia, { data: closingMediaMediaData }] = useLazyQuery(
    queries.getMediaByType,
    {
      onCompleted: data => {
        setValue(
          'closingVideos',
          editorMediaArrayToObject(data.seenit.editorMedia)
        );
      },
    }
  );

  const [getaudioTracksMedia, { data: audioTracksMediaData }] = useLazyQuery(
    queries.getMediaByType,
    {
      onCompleted: data => {
        setValue(
          'musicTracks',
          editorMediaArrayToObject(data.seenit.editorMedia)
        );
      },
    }
  );

  const [getLowerThirds, { data: lowerThirdsData }] = useLazyQuery(
    queries.getLowerThirds,
    {
      onCompleted: data => {
        setValue(
          'lowerThirds',
          lowerThirdsArrayToObject(data.seenit.lowerThirds)
        );
      },
    }
  );
  useEffect(() => {
    if (orgId) {
      getopeningMediaMedia({
        variables: { filter: { mediaType: 'openingMedia', orgId } },
      });
      getclosingMediaMedia({
        variables: { filter: { mediaType: 'closingMedia', orgId } },
      });
      getaudioTracksMedia({
        variables: { filter: { mediaType: 'audioTracks', orgId } },
      });
      getLowerThirds({ variables: { filter: { orgId } } });
    }
  }, [orgId]);

  useEffect(() => {
    if (
      openingMediaMediaData &&
      closingMediaMediaData &&
      audioTracksMediaData &&
      lowerThirdsData
    ) {
      editorBrandsDefaultFormPropsVar({
        ...editorBrandsDefaultFormProps,
        openingVideos: editorMediaArrayToObject(
          openingMediaMediaData.seenit.editorMedia
        ),
        closingVideos: editorMediaArrayToObject(
          closingMediaMediaData.seenit.editorMedia
        ),
        musicTracks: editorMediaArrayToObject(
          audioTracksMediaData.seenit.editorMedia
        ),
        lowerThirds: lowerThirdsArrayToObject(
          lowerThirdsData.seenit.lowerThirds
        ),
      });
      editorBrandsOrgDataVar({
        ...editorBrandsOrgData,
        openingVideos: openingMediaMediaData.seenit.editorMedia,
        closingVideos: closingMediaMediaData.seenit.editorMedia,
        musicTracks: audioTracksMediaData.seenit.editorMedia,
        lowerThirds: lowerThirdsData.seenit.lowerThirds,
      });
    }
  }, [
    openingMediaMediaData,
    closingMediaMediaData,
    audioTracksMediaData,
    lowerThirdsData,
  ]);

  const buttonTitle = () => {
    return selectedBrand ? 'Update brand' : 'Create brand';
  };

  const formObjectIntoArray = formObject => {
    const array = Object.keys(formObject).filter(
      key => formObject[key] === true
    );

    return array;
  };

  const formFilterObject = formObject => {
    const obj = Object.fromEntries(
      Object.entries(formObject)
        .filter(([, value]) => Boolean(value))
        .map(([key, value]) => {
          return value === '-1' ? [key, null] : [key, value];
        })
    );

    return obj;
  };

  const mutationData = () => ({
    closingVideos: formObjectIntoArray(getValues('closingVideos')),
    logo: formFilterObject(getValues('logo')),
    lowerThirds: formObjectIntoArray(getValues('lowerThirds')),
    musicTracks: formObjectIntoArray(getValues('musicTracks')),
    name: getValues('name'),
    openingVideos: formObjectIntoArray(getValues('openingVideos')),
    orientation: getValues('orientation'),
  });

  const buttonClicked = () => {
    if (selectedBrand) {
      updateBrand({
        variables: {
          data: { ...mutationData() },
          brandId: selectedBrand,
        },
      });
    } else {
      createBrand({
        variables: { data: { ...mutationData(), orgId } },
      });
    }
  };

  return (
    <Styled.Wrapper>
      <Pill
        color="red"
        readOnly
        text={selectedBrand ? `Updating brand` : `Creating brand`}
      />

      <form onSubmit={handleSubmit(buttonClicked)}>
        <Styled.InnerFormWrapper>
          <Styled.InputFields>
            <Controller
              name="name"
              control={control}
              render={({ field }) => (
                <Styled.InputField>
                  <Typography
                    lines={0}
                    variant="label"
                    css={{ marginBottom: '4px' }}
                  >
                    Brand name
                  </Typography>
                  <TextInput
                    {...field}
                    placeholder="Brand name goes here..."
                    dataEl={dataEl}
                    value={getValues('name') ?? ''}
                  />
                  <FormError errors={errors} field="name" />
                </Styled.InputField>
              )}
            />
            <Controller
              name="orientation"
              control={control}
              render={({ field }) => (
                <Styled.InputField>
                  <Typography
                    lines={0}
                    variant="label"
                    css={{ marginBottom: '4px' }}
                  >
                    orientation
                  </Typography>
                  <Select
                    {...field}
                    options={[
                      { text: 'Please select an orientation', value: -1 },
                      { text: 'any', value: 'any' },
                      { text: 'landscape', value: 'landscape' },
                      { text: 'portrait', value: 'portrait' },
                      { text: 'square', value: 'square' },
                    ]}
                    id="defaultGroups"
                    value={getValues('orientation') ?? -1}
                    dataEl="DefaultGroupsInput"
                    onChange={val => {
                      if (val === '-1') setValue('orientation', null);
                      else setValue('orientation', val);
                      trigger('orientation');
                    }}
                  />
                  <FormError errors={errors} field="orientation" />
                </Styled.InputField>
              )}
            />
          </Styled.InputFields>
          <Tabs
            onChange={setBriefCurrentTab}
            selectedTab={briefCurrentTab}
            tabs={tabs}
            tabStyle="switch"
          />

          {briefCurrentTab && (
            <BrandsFormSection
              dataEl={dataEl}
              orgId={orgId}
              section={briefCurrentTab}
            />
          )}

          <Button
            animation="pulse"
            dataEl="OpeningMediaSubmitButton"
            id="openingMediaSubmit"
            large
            type="submit"
            width="100%"
          >
            {buttonTitle()}
          </Button>
          <FormErrors errors={errors} />
          <Error rawErrors={createBrandError || updateBrandError} />
          {!!rootElement &&
            createPortal(
              <Toast
                dataEl={`${dataEl}Toast`}
                autoClose="3000"
                containerId={`${dataEl}ToastContainer`}
                position="top-center"
                ref={toastRef}
                toastId={`${dataEl}Toast`}
              />,
              rootElement
            )}
        </Styled.InnerFormWrapper>
      </form>
    </Styled.Wrapper>
  );
};
